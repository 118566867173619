import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import pic04 from '../assets/images/schaufenster.jpg'
import Header from '../components/Header'
import { Link } from 'gatsby'

class Bestattungen extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="Bestattungsvorsorge | Bestattungshaus Bartko" />

        <Header isChildPage={true} />
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>

            <h1>Bestattungsvorsorge</h1>
            <p>"Heute schon an Morgen denken“ - Es ist oftmals sehr schwer, sich zu Lebzeiten mit dem Tod zu beschäftigen. Aber im Wandel der Zeit merken wir, wie wichtig Vorsorge ist.</p>

            <p>Dazu gehört auch die finanzielle Absicherung und Festlegung der Wünsche für Ihre eigene Bestattung.
              Damit sind Sie Ihren Angehörigen eine große Hilfe.
              Mit einem Bestattungsvorsorgevertrag legen Sie selbst fest:
            </p>
            <ul>
              <li>Bestattungsart (Erd-, Feuer-, Baum- oder Seebestattung)</li>
              <li>Art und Weise der Trauerfeier und Beisetzung</li>
              <li>Auswahl von Sarg, Urne und Ausstattung</li>
              <li>Auf welchem Friedhof die Beisetzung stattfinden soll</li>
              <li>Kosten der Bestattung</li>
            </ul>

            <center>
              <Link to="/" className="button">
                Zurück zur Übersicht
              </Link>
            </center>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Bestattungen
